<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>商户</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="商户名称">
                                <el-input v-model="search.company_name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="联系人姓名">
                                <el-input v-model="search.company_contacts"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="联系方式">
                                <el-input v-model="search.company_contacts_mobile"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="城市">
                                <el-input v-model="search.city"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="统一社会信用">
                                <el-input v-model="search.uniform_social_credit_code"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="商户类型">
                                <el-select v-model="search.type" placeholder="请选择类型" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="商户" value="1"></el-option>
                                    <el-option label="个人" value="2"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="商户类别">
                                <el-select v-model="search.merchant_category_id" placeholder="请选择商户类别" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option v-for="(item, index) in categoryData" :key="index" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="时间">
                                <el-select v-model="search.time_type" style="width: 30%">
                                    <el-option label="创建时间" value="create_time"></el-option>
                                    <el-option label="更新时间" value="update_time"></el-option>
                                    <el-option label="过期时间" value="expiration_time"></el-option>
                                </el-select>
                                <el-date-picker style="width: 70%" v-model="searchtime" type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日"
                                    value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>

                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <div style="display:flex;align-items: center;">
                        <h3 style="margin-right:10px">{{ page_name }}</h3>
                        <el-button v-if="is_auth('company.company.batchossuser')" size="medium" type="primary"
                            @click="batchEditCompany('user')">
                            设置管理人员信息
                        </el-button>
                        <el-button v-if="is_auth('company.company.batchmerchantcategory')" size="medium" type="primary"
                            @click="batchEditCompany('category')">
                            设置商户类别
                        </el-button>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <!-- <el-button v-if="is_auth('company.company.batchossuser')" :disabled="isBatchShow" size="medium"
                            type="primary" @click="openBatch">
                            批量修改管理人员信息
                        </el-button> -->
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                            @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                            @click="issearch = !issearch">显示搜索
                        </el-button>
                        <!-- <el-button v-if="is_auth('company.company.issave')" icon="el-icon-plus" size="medium" type="primary"
                            @click="tosee()">添加
                        </el-button> -->
                        <el-button icon="el-icon-download" size="medium" type="primary" @click="exportOrder">导出
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table v-loading="loading" ref="companyTable" :data="tableData" border size="medium" style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection">
                </el-table-column>
                <el-table-column prop="company_name" label="商户名称" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="company_contacts" label="负责人姓名" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="company_contacts_mobile" label="联系方式" width="120">
                </el-table-column>
                <el-table-column prop="oss_user_name" label="商户运营" width="120">
                    <template slot-scope="scope">
                        <el-link v-if="is_auth('company.company.batchossuser')" style="width: 100%; text-align: center"
                            slot="reference" type="primary" @click="startEdit('user', scope.row)">
                            <p style="text-align:center;margin:0" v-if="scope.row.oss_user_name">
                                {{ scope.row.oss_user_name }}</p>
                            <p style="text-align:center;margin:0" v-else>/</p>
                        </el-link>
                        <div v-else>
                            <p style="text-align:center;margin:0" v-if="scope.row.oss_user_name">
                                {{ scope.row.oss_user_name }}</p>
                            <p style="text-align:center;margin:0" v-else>/</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="oss_user_mobile" label="商户运营联系方式" width="140">
                    <template slot-scope="scope">
                        <div>
                            <p style="text-align:center;margin:0" v-if="scope.row.oss_user_mobile">
                                {{ scope.row.oss_user_mobile }}</p>
                            <p style="text-align:center;margin:0" v-else>/</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="category_name" label="商户类别" width="120">
                    <template slot-scope="scope">
                        <el-link v-if="is_auth('company.company.batchmerchantcategory')"
                            style="width: 100%; text-align: center" slot="reference" type="primary"
                            @click="startEdit('category', scope.row)">
                            <p style="text-align:center;margin:0" v-if="scope.row.category_name">
                                {{ scope.row.category_name }}</p>
                            <p style="text-align:center;margin:0" v-else>/</p>
                        </el-link>
                        <div v-else>
                            <p style="text-align:center;margin:0" v-if="scope.row.category_name">
                                {{ scope.row.category_name }}</p>
                            <p style="text-align:center;margin:0" v-else>/</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="oss_merchant_uuid" label="运营商" width="120">
                    <template slot-scope="scope">
                        <el-link v-if="is_auth('company.company.batchossmerchant')" style="width: 100%; text-align: center"
                            slot="reference" type="primary" @click="startEdit('merchant', scope.row)">
                            <p style="text-align:center;margin:0" v-if="scope.row.oss_merchant_uuid">
                                {{ scope.row.oss_merchant_uuid }}</p>
                            <p style="text-align:center;margin:0" v-else>/</p>
                        </el-link>
                        <div v-else>
                            <p style="text-align:center;margin:0" v-if="scope.row.oss_merchant_uuid">
                                {{ scope.row.oss_merchant_uuid }}</p>
                            <p style="text-align:center;margin:0" v-else>/</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="city" label="企业地址" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <p>{{ `${scope.row.province}${scope.row.city}${scope.row.county}${scope.row.address}` }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="uniform_social_credit_code" label="统一社会信用代码/身份证号码" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="type" label="类型" width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.type === 1">商户</el-tag>
                        <el-tag size="mini" v-if="scope.row.type === 2" type="warning">个人</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="state" label="账号状态" width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.state === 1">启用</el-tag>
                        <el-tag size="mini" v-if="scope.row.state === 2" type="warning">禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间" width="180">
                </el-table-column>
                <el-table-column prop="update_time" label="更新时间" width="180">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="240">
                    <template slot-scope="scope">
                        <el-button v-if="is_auth('company.company.getinfo')" @click="tosee(scope.row)" size="mini">查看
                        </el-button>
                        <el-button
                            v-if="is_auth('company.company.isenable') && scope.row.state === 2 && scope.row.is_sysadmin !== 1"
                            @click="operation_tip(scope.row.company_uuid, scope.row.company_name, 'isenable')"
                            type="primary" size="mini">启用
                        </el-button>
                        <el-button
                            v-if="is_auth('company.company.isdisable') && scope.row.state === 1 && scope.row.is_sysadmin !== 1"
                            @click="operation_tip(scope.row.company_uuid, scope.row.company_name, 'isdisable')"
                            type="warning" size="mini">禁用
                        </el-button>
                        <el-button v-if="is_auth('company.company.passwordset')"
                            @click="operation_tip(scope.row.company_uuid, scope.row.company_name, 'passwordset')"
                            type="primary" size="mini">强改密码
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
                layout="prev, pager, next, total" :current-page.sync="page" :total="count">
            </el-pagination>
        </div>
        <!-- 单选修改 -->
        <SingleEdit :edit-type="'company'" :loading="editLoading" :visible="isEditModelShow" :type="editType"
            :choose-merchant="chooseMerchant" :is-auth-batchossuser="is_auth('company.company.batchossuser')"
            :oss_user_uuid="oss_user_uuid" :merchant_category_id="merchant_category_id"
            :availableuser-data="availableuserData" :category-data="categoryData" @clearState="clearState" @save="save"
            @changeTemplate="changeTemplate" />
        <!-- 批量修改 -->
        <BatchEdit :edit-type="'company'" :loading="editLoading" :visible="isBatchEditModelShow" :type="editType"
            :choose-company="chooseCompany" :is-auth-batchossuser="is_auth('company.company.batchossuser')"
            :oss_user_uuid="oss_user_uuid" :merchant_category_id="merchant_category_id"
            :availableuser-data="availableuserData" :category-data="categoryData" @clearState="clearState"
            @changeTemplate="changeTemplate" @batchSave="batchSave" />
        <el-dialog width="50%" title="查看商户认证信息详情" :visible.sync="isDialogShow" :close-on-press-escape="false"
            :close-on-click-modal="false" @close="clearInfoState">
            <DialogDetail :choose-merchant="chooseMerchant" @onClose="clearInfoState" @smsset="smsset" />
        </el-dialog>
    </div>
</template>
<script>
import SingleEdit from "./../../../components/merchantEdit/SingleEdit";
import BatchEdit from "./../../../components/merchantEdit/BatchEdit";
import DialogDetail from "./DialogDetail";
export default {
    components: { SingleEdit, BatchEdit, DialogDetail },
    data() {
        return {
            page_name: '商户',
            loading: true,      // 加载状态
            editLoading: false, // 编辑框加载状态
            issearch: false,
            isBatchShow: false, // 批量是否显示
            isEditModelShow: false, // 单个修改企业信息
            isBatchEditModelShow: false, // 批量修改企业信息
            isDialogShow: false,
            search: {},// 搜索内容
            count: 0,        // 数据总条数
            tableData: [],// 列表内容
            chooseCompany: [], // 当前选中的企业信息
            page: 1,            // 当前页数
            searchtime: null,   // 时间条件

            editType: "", // 修改企业信息类型 merchant --> 修改运营商 user --> 修改商户运营
            oss_user_uuid: "", // 选中的商户运营人员id
            merchant_category_id: 0, // 选中的商户类别id
            availableuserData: [], // 可用商户运营人员列表
            categoryData: [], // 可用商户类别列表
            chooseMerchant: {}, // 当前选中的商户信息
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                company_name: '',
                company_contacts: '',
                company_contacts_mobile: '',
                uniform_social_credit_code: '',
                city: '',
                type: '',
                time_type: 'create_time',
                merchant_category_id: "",
            }
            this.searchtime = null
            this.is_search();
            this.getAvailableuser();
            this.getCategoryList();
        },
        // 获取可用商户运营人员列表
        getAvailableuser() {
            let postdata = {
                api_name: "systemset.user.availableuser",
                token: this.Tool.get_l_cache('token'),
            };
            this.Tool.post_data('oss', postdata, (json) => {
                if (json.code === 0) {
                    this.availableuserData = json.data.list
                }
            })
        },
        // 获取可用商户类别
        getCategoryList() {
            let postdata = {
                api_name: "company.merchantcategory.getusablelist",
                token: this.Tool.get_l_cache('token'),
            };
            this.Tool.post_data('oss', postdata, (json) => {
                if (json.code === 0) {
                    this.categoryData = json.data;
                }
            })
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "company.company.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            // 判断时间条件
            if (this.searchtime !== null) {
                postdata.starttime = this.searchtime[0]
                postdata.endtime = this.searchtime[1]
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(company_uuid = '', company_name = '', operation = '') {

            let tip = ''
            let options = {}
            // 启用
            if (operation === 'isenable') {
                tip = '启用【' + company_name + '】？'
            }
            // 禁用
            if (operation === 'isdisable') {
                tip = '禁用【' + company_name + '】？'
            }
            // 删除
            if (operation === 'isdel') {
                tip = '删除【' + company_name + '】？'
                options = { type: 'error' }
            }
            // 强改密码
            if (operation === 'passwordset') {
                tip = '强改【' + company_name + '】密码为：1'
                options = { type: 'error' }
            }

            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                .then(() => {
                    this.isoperation(company_uuid, operation)
                })
                .catch(() => {
                });
        },
        // 操作
        isoperation(company_uuid = '', operation = '') {
            let postdata = {
                api_name: "company.company." + operation,
                token: this.Tool.get_l_cache('token'),
                company_uuid: company_uuid,
            }
            this.Tool.post_data('oss', postdata, (json) => {
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 查看
        tosee(item) {
            this.chooseMerchant = item;
            this.isDialogShow = true;
        },
        // 开启批量选择
        openBatch() {
            this.isBatchShow = true;
            this.chooseCompany = [];
        },
        // 批量修改选中企业
        batchEditCompany(type) {
            const { chooseCompany } = this;
            if (chooseCompany.length === 0) {
                this.$message.warning("请选择需要修改的企业");
            } else {
                this.isBatchEditModelShow = true;
                this.editType = type;
            }
        },
        // 获取选中的企业
        handleSelectionChange(e) { this.chooseCompany = e },
        // 清楚多选的企业
        // toggleSelection() { this.$refs.companyTable.clearSelection(); },
        // 清除数据
        clearState() {
            this.isEditModelShow = false;
            this.isBatchShow = false;
            this.chooseMerchant = null;
            this.isBatchEditModelShow = false;
            this.oss_user_uuid = "";
            this.merchant_category_id = 0;
            this.editType = "";
            // this.chooseCompany = [];
            this.toggleSelection();
        },
        startEdit(type, item) {
            if (item.oss_user_uuid) {
                this.oss_user_uuid = item.oss_user_uuid;
            }
            this.editType = type;
            this.isEditModelShow = true;
            this.chooseMerchant = item;
        },
        changeTemplate(e) {
            const { editType } = this;
            if (editType === 'user') {
                this.oss_user_uuid = e;
            } else {
                this.merchant_category_id = e;
            }
        },
        // 保存商户运营人员信息
        save() {
            const { chooseMerchant } = this;
            this.submitPost([chooseMerchant.company_uuid]);
        },
        // 批量修改
        batchSave() {
            const { chooseCompany, oss_user_uuid, editType, merchant_category_id } = this;
            let company_uuids = [];
            if (editType === 'user') {
                if (!oss_user_uuid) {
                    this.$message.warning("请选择需要替换的商户运营人员");
                } else {
                    chooseCompany.map(item => company_uuids.push(item.company_uuid));
                    this.submitPost(company_uuids);
                }
            } else if (editType === 'category') {
                if (merchant_category_id === 0) {
                    this.$message.warning("请选择需要替换的商户类别");
                } else {
                    chooseCompany.map(item => company_uuids.push(item.company_uuid));
                    this.submitPost(company_uuids);
                }
            }
        },
        submitPost(company_uuids) {
            const { oss_user_uuid, merchant_category_id, editType } = this;
            let postdata = {
                token: this.Tool.get_l_cache('token'),
                company_uuids,
            };
            if (editType === 'user') {
                postdata['api_name'] = 'company.company.batchossuser';
                if (oss_user_uuid) {
                    postdata['oss_user_uuid'] = oss_user_uuid;
                } else {
                    this.$message.warning("请选择需要设置的商户运营人员");
                    return false;
                }
            } else if (editType === 'category') {
                postdata['api_name'] = 'company.company.batchmerchantcategory';
                if (merchant_category_id) {
                    postdata['merchant_category_id'] = merchant_category_id;
                } else {
                    this.$message.warning("请选择需要设置的商户类别");
                    return false;
                }
            }
            this.editLoading = true;
            this.Tool.post_data('oss', postdata, (json) => {
                this.editLoading = false;
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist();  // 刷新列表(重新搜索)
                            this.clearState();
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        clearInfoState() {
            this.isDialogShow = false;
            this.chooseMerchant = null;
        },
        //设置短信提醒
        smsset(val) {
            let postdata = {
                api_name: "company.company.smsset",
                token: this.Tool.get_l_cache('token'),
                company_uuid: this.chooseMerchant.company_uuid,
                issms: val,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.chooseMerchant.issms = val
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 导出
        exportOrder() {
            let postdata = {
                api_name: "company.company.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
                export: "yes",
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            // 判断时间条件
            if (this.searchtime !== null) {
                postdata.starttime = this.searchtime[0]
                postdata.endtime = this.searchtime[1]
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (res) => {
                if (res.code === 10005) {
                    this.Tool.errormes(res);
                } else {
                    location.href = res;
                }
                this.loading = false;
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
</style>
