<!-- 单独商户信息修改 -->
<template>
    <el-dialog v-loading="loading" :visible="visible" :title="returnTitle" width="50%" :close-on-press-escape="false"
        :close-on-click-modal="false" @close="clearState">
        <el-row v-if="chooseMerchant">
            <el-form size="medium" ref="form" :label-width="this.env.label_width">
                <el-col :span="24">
                    <el-form-item label-width="140px" :label="editType === 'company' ? '公司名称：' : '门店名称：'">
                        <span>{{ editType === 'company' ? chooseMerchant.company_name :
                            chooseMerchant.store_name }}</span>
                    </el-form-item>
                </el-col>
                <template v-if="type === 'merchant'">
                    <el-col :span="24">
                        <el-form-item label-width="140px" label="当前运营商：">
                            <span>{{ chooseMerchant.oss_merchant_uuid ? chooseMerchant.oss_merchant_uuid :
                                '无' }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label-width="140px" label="运营商选择：">
                            <el-select :disabled="true" v-model="name" style="width: 60%" placeholder="暂不支持修改"
                                @change="changeTemplate">
                            </el-select>
                        </el-form-item>
                    </el-col>
                </template>
                <!-- 商户类别设置 -->
                <template v-else-if="type === 'category'">
                    <el-col :span="24">
                        <el-form-item label-width="140px" label="当前商户类别：">
                            <span>{{ chooseMerchant.merchant_category_id !== 0 ? chooseMerchant.category_name :
                                '无' }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label-width="140px" label="商户类别选择：">
                            <el-select :value="merchant_category_id === 0 ? '' : merchant_category_id" style="width: 60%"
                                placeholder="请选择商户类别" @change="changeTemplate">
                                <el-option v-for="item in categoryData" :key="item.id" :label="item.name"
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </template>
                <template v-else>
                    <el-col :span="24">
                        <el-form-item label-width="140px" label="当前商户运营人员：">
                            <span>{{ chooseMerchant.oss_user_uuid ? chooseMerchant.oss_user_name :
                                '无' }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label-width="140px" label="商户运营人员选择：">
                            <el-select :value="oss_user_uuid" style="width: 60%" placeholder="请选择运营商信息"
                                @change="changeTemplate">
                                <el-option v-for="item in availableuserData" :key="item.user_uuid" :label="item.name"
                                    :value="item.user_uuid" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </template>
            </el-form>
        </el-row>
        <el-row style="margin: 10px 0px">
            <el-button size="medium" type="primary" @click="save">
                确认修改
            </el-button>
            <el-button size="medium" @click="clearState">取消修改</el-button>
        </el-row>
    </el-dialog>
</template>

<script>
export default {
    props: {
        loading: { // 加载状态
            type: Boolean,
            default: false
        },
        visible: { // 弹窗是否显示
            type: Boolean,
            default: false,
        },
        type: { // 修改企业信息类型 merchant -- > 修改运营商 user -- > 修改商户运营
            type: String,
            default: "merchant" | "user" | "category"
        },
        chooseMerchant: { // 当前选中的企业信息
            type: Object,
            default: null
        },
        oss_user_uuid: { // 当前选中的商户运营人员id
            type: String,
            default: "",
        },
        merchant_category_id: { // 当前选中的商户类别id
            type: Number,
            default: 0,
        },
        isAuthBatchossuser: {
            type: Boolean,
            default: false,
        },
        availableuserData: { // 可用商户运营人员列表
            type: Array,
            default: () => { [] }
        },
        categoryData: { // 可用商户类别列表
            type: Array,
            default: () => { [] }
        },
        editType: { // 需要修改的类型 store --> 门店 company --> 企业
            type: String,
            default: "store" | "company"
        }
    },
    data() {
        return {
            name: ""
        }
    },
    computed: {
        returnTitle() {
            let editTypeText = "", typeText = "";
            const { type, editType } = this;
            editTypeText = editType === 'store' ? '修改门店' : editType === 'company' ? '修改企业' : '';
            typeText = type === 'merchant' ? '运营商信息' : type === 'user' ? '运营人员信息' : type === 'category' ? '商户类别' : ''

            return `${editTypeText}${typeText}`;
        }
    },
    methods: {
        // 关闭弹窗并且情况数据
        clearState() {
            this.$emit("clearState");
        },
        // 提交表单
        save() {
            this.$emit("save");
        },
        changeTemplate(e) {
            this.$emit("changeTemplate", e);
        }
    }
}
</script>