<!-- 批量商户信息修改 -->
<template>
    <el-dialog v-loading="loading" :visible="visible" :title="editType === 'company' ? '批量修改企业管理人员信息' : '批量修改门店管理人员信息'"
        width="820px" :close-on-press-escape="false" :close-on-click-modal="false" @close="clearState">
        <el-row>
            <el-form size="medium" ref="form" :label-width="this.env.label_width">
                <el-col :span="24">
                    <h3 style="margin:0;margin-bottom:24px">已选择{{ chooseCompany.length }}个{{ editType === 'company' ? '企业'
                        : '门店' }}</h3>
                </el-col>
                <section class="comp_batchMerchant">
                    <div v-for="(item, index) in chooseCompany" :key="index" style="display: inline-block">
                        <el-card class="comp_batchMerchant_item" body-style="padding:10px;display: inline-block;">
                            <p class="comp_batchMerchant_item_title">
                                {{ editType === 'company' ? item.company_name : item.store_name }}
                            </p>
                            <template v-if="type === 'user'">
                                <p>当前运营商：{{ item.oss_merchant_uuid ? item.oss_merchant_uuid :
                                    '无' }}</p>
                                <p>当前商户运营人员：{{ item.oss_user_uuid ? item.oss_user_name :
                                    '无' }}</p>
                            </template>
                            <template v-else-if="type === 'category'">
                                <p>当前商户类别：{{ item.merchant_category_id !== 0 ? item.category_name :
                                    '无' }}</p>
                            </template>
                        </el-card>
                    </div>
                </section>
                <template v-if="type === 'user'">
                    <el-col :span="24">
                        <el-form-item label-width="140px" label="运营商选择：">
                            <el-select :disabled="true" value="" style="width: 60%" placeholder="暂不支持修改"
                                @change="changeTemplate">
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label-width="140px" label="商户运营人员选择：">
                            <el-select :value="oss_user_uuid" style="width: 60%" placeholder="请选择运营商信息"
                                @change="changeTemplate">
                                <el-option v-for="item in availableuserData" :key="item.user_uuid" :label="item.name"
                                    :value="item.user_uuid" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </template>
                <template v-else-if="type === 'category'">
                    <el-col :span="24">
                        <el-form-item label-width="140px" label="商户类别选择：">
                            <el-select :value="merchant_category_id === 0 ? '' : merchant_category_id" style="width: 60%" placeholder="请选择商户类别"
                                @change="changeTemplate">
                                <el-option v-for="item in categoryData" :key="item.id" :label="item.name"
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </template>
            </el-form>
        </el-row>
        <el-row style="margin: 10px 0px">
            <el-button v-if="isAuthBatchossuser" size="medium" type="primary" @click="save">
                确认修改
            </el-button>
            <el-button size="medium" @click="clearState">取消修改</el-button>
        </el-row>
    </el-dialog>
</template>

<script>
import "./index.css";
export default {
    props: {
        type: { // 修改企业信息类型 merchant -- > 修改运营商 user -- > 修改商户运营
            type: String,
            default: "user" | "category"
        },
        loading: { // 加载状态
            type: Boolean,
            default: false
        },
        visible: { // 弹窗是否显示
            type: Boolean,
            default: false,
        },
        oss_user_uuid: { // 当前选中的商户运营人员id
            type: String,
            default: "",
        },
        merchant_category_id: { // 当前选中的商户类别id
            type: Number,
            default: 0,
        },
        isAuthBatchossuser: {
            type: Boolean,
            default: false,
        },
        availableuserData: { // 可用商户运营人员列表
            type: Array,
            default: () => { [] }
        },
        categoryData: { // 可用商户类别列表
            type: Array,
            default: () => { [] }
        },
        chooseCompany: { // 多选的企业信息
            type: Array,
            default: () => { [] }
        },
        editType: { // 需要修改的类型 store --> 门店 company --> 企业
            type: String,
            default: "store" | "company"
        }
    },
    methods: {
        // 关闭弹窗并且情况数据
        clearState() {
            this.$emit("clearState");
        },
        // 提交表单
        save() {
            this.$emit("batchSave");
        },
        changeTemplate(e) {
            this.$emit("changeTemplate", e);
        }
    }
}
</script>

