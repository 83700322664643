<template>
    <section v-if="chooseMerchant">
        <el-row v-if="!isShowRemark">
            <el-col>
                <el-form size="medium" :label-width="this.env.search_label_width">
                    <el-form-item label="商户名称：">
                        <p style="margin:0">{{ chooseMerchant.company_name }}</p>
                    </el-form-item>
                    <el-form-item label="负责人姓名：">
                        <p style="margin:0">{{ chooseMerchant.contact_name ? chooseMerchant.contact_name :
                            chooseMerchant.company_contacts }}</p>
                    </el-form-item>
                    <el-form-item label="联系方式：">
                        <p style="margin:0">{{ chooseMerchant.contact_mobile ? chooseMerchant.contact_mobile :
                            chooseMerchant.company_contacts_mobile }}</p>
                    </el-form-item>
                    <el-form-item label="企业地址：">
                        <p style="margin:0">
                            {{ `${chooseMerchant.province}${chooseMerchant.city}${chooseMerchant.county}${chooseMerchant.address}` }}
                        </p>
                    </el-form-item>
                    <el-form-item label="统一社会信用代码/身份证号码：" label-width="220px">
                        <p style="margin:0">{{ chooseMerchant.uniform_social_credit_code }}</p>
                    </el-form-item>
                    <el-form-item label="商户类型：">
                        <el-tag size="mini" v-if="chooseMerchant.type === 1">商户</el-tag>
                        <el-tag size="mini" v-if="chooseMerchant.type === 2" type="warning">个人</el-tag>
                    </el-form-item>
                    <el-form-item label="品牌logo：">
                        <div v-if="chooseMerchant.logo" style=" width:40px;height:40px">
                            <el-image :z-index="9999" fit="fit" :preview-src-list="[chooseMerchant.logo]"
                                :src="chooseMerchant.logo" />
                        </div>
                    </el-form-item>
                    <el-form-item label="营业执照/身份证正反面照片：" label-width="220px">
                        <div v-for="(item, index) in chooseMerchant.business_license" :key="index"
                            style="width:40px;height:40px">
                            <el-image :z-index="9999" fit="fit" :preview-src-list="chooseMerchant.business_license"
                                :src="item" />
                        </div>
                    </el-form-item>
                    <el-form-item label="短信通知">
                        <el-switch v-model="chooseMerchant.issms" :active-value="1" :inactive-value="0" @change="smsset">
                        </el-switch>
                    </el-form-item>
                    <el-form-item>
                        <section style="display:flex;justify-content: space-between;">
                            <el-button size="medium" @click="onClose">关闭</el-button>
                            <div>
                                <el-button v-if="is_auth('company.company.examinenew') && chooseMerchant.status === 0"
                                    size="medium" type="success" @click="examine(1)">通过
                                </el-button>
                                <el-button v-if="is_auth('company.company.examinenew') && chooseMerchant.status === 0"
                                    size="medium" type="danger" @click="showRemark">拒绝
                                </el-button>
                            </div>
                        </section>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <div v-else>
            <h3 style="margin:0 0 20px">填写拒绝原因</h3>
            <el-input type="textarea" resize="none" :rows="10" placeholder="请输入内容" v-model="textarea" maxlength="300"
                show-word-limit>
            </el-input>
            <section style="margin-top: 20px;">
                <el-button size="medium" @click="onCancleReject">取消</el-button>
                <el-button v-if="is_auth('company.company.examinenew') && chooseMerchant.status === 0" size="medium"
                    type="danger" @click="examine(2)">拒绝
                </el-button>
            </section>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        chooseMerchant: {
            type: Object,
            default: null
        },
        is_auth: {
            type: Function,
            default: () => { }
        },
        isShowRemark: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            textarea: "",
            issms: 0
        }
    },
    methods: {
        // 判断是否是空
        is_empty(data) {
            return this.Tool.is_empty(data)
        },
        onClose() {
            this.textarea = "";
            this.$emit("onClose");
        },
        showRemark() {
            this.$emit("onRemark", "show");
        },
        onCancleReject() {
            this.$emit("onRemark", "cancle");
            this.textarea = "";
        },
        // 审核逻辑请求
        examine(type) {
            this.$emit("examine", type, this.textarea, this.chooseMerchant.id);
        },
        //设置短信提醒
        smsset(val) {
            this.$emit("smsset", val);
        },
    }
}
</script>